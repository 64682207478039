<script>
import "./plugins/datepicker.css";
import "./plugins/datepicker.js";

export default {
  props: {
    dateStart: Date,
    value: String,
    dateType: String,
    config: Object,
    valueFormat: { default: "YYYY-MM-DD" },
  },
  computed: {
    dateFormat() {
      if (this.pluginOptions.format == "dd-mm-yyyy") return "DD-MM-YYYY";
      return this.valueFormat;
    },
    pluginOptions() {
      var option = {
        todayHighlight: true,
        autoclose: true,
        orientation: "bottom",
        format: "dd-mm-yyyy",
      };
      if (this.dateType == "dob")
        option = {
          orientation: "bottom",
          format: "dd-mm-yyyy",
          calendarWeeks: true,
          autoclose: true,
          todayHighlight: true,
          startView: "decade",
          endDate: "-15y",
        };
      if (this.dateType == "idcard")
        option = {
          orientation: "bottom",
          format: "dd-mm-yyyy",
          calendarWeeks: true,
          autoclose: true,
          todayHighlight: true,
          startView: "decade",
          endDate: "+5y",
        };
      if (this.dateType == "dob_wali")
        option = {
          orientation: "bottom",
          format: "dd-mm-yyyy",
          calendarWeeks: true,
          autoclose: true,
          todayHighlight: true,
          startView: "decade",
          endDate: "-15y",
        };
      if (this.dateType == "year")
        option = {
          orientation: "bottom",
          format: "yyyy",
          viewMode: "years",
          minViewMode: "years",
          calendarWeeks: true,
          autoclose: true,
          todayHighlight: true,
          startView: "decade",
        };
      if (this.dateType == "mothYear")
        option = {
          orientation: "bottom",
          format: "mm-yyyy",
          viewMode: "months",
          minViewMode: "months",
          autoclose: true,
          todayHighlight: true,
        };
      return Object.assign(option, this.config || {});
    },
  },
  mounted: function() {
    this.init();
    setTimeout(() => {
      $(this.$el).datepicker();
    }, 300);
  },
  methods: {
    init() {
      if (this.value) this.$emit("input", this.value.dates(this.dateFormat));
      $(this.$el)
        .datepicker(this.pluginOptions)
        .on("change press", (e) => {
          var val = e.target.value.dates(this.valueFormat, this.dateFormat);
          if (this.dateType == "mothYear") {
            val = e.target.value;
          }
          this.$emit("input", val);
          if ($(this.$el).closest("form").length) $(this.$el).valid();
        });
      if (this.value) {
        $(this.$el).datepicker("update", this.value.dates(this.dateFormat));
      }
    },
  },
  watch: {
    value(v) {
      if (!v) return $(this.$el).datepicker("update", "");
      if (this.dateType == "mothYear") {
        this.$emit("input", v);
        $(this.$el).datepicker("update", v);
      } else {
        this.$emit("input", v.dates(this.valueFormat, this.dateFormat));
        $(this.$el).datepicker("update", v.dates(this.dateFormat));
      }
    },
  },
};
</script>

<template>
  <input type="text" class="form-control input-sm" autocomplete="off" />
</template>
